
































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import b64toBlob from 'b64-to-blob'
import moment from 'moment'

@Component
export default class ReportsSuppliersPurchasesList extends Vue {
  $refs: Vue['$refs'] & {
    sendReportForm: {
      validate: any
    }
  }

  selected = []

  downloadReportLoading = false
  sendReportLoading = false

  sendReportDialog = false
  sendReportEmails = null
  sendReportSubject = null
  sendReportMessage = null
  sendReportId = null

  headers = []
  reports = []
  search = ''

  pagination = {
    descending: true,
    page: 1,
    itemsPerPage: 15,
    sortBy: [],
  }

  get sendReportRequestData() {
    return {
      report_id: this.sendReportId,
      send_report_emails: this.sendReportEmails,
      send_report_subject: this.sendReportSubject,
      send_report_message: this.sendReportMessage,
    }
  }

  created() {
    this.$axios.get('/v3/reports/suppliers/purchases/list/get_initial_data').then((response) => {
      this.headers = response.data.data.headers
      this.reports = response.data.data.reports
    })
  }

  getExcelFileName(reportData) {
    const date = moment().format('YYYY-MM-DD')
    let result = 'supplier_purchase_report'

    result += '_' + reportData.supplier_name.toLowerCase() + '_' + reportData.currency

    return result + '-' + date + '.xlsx'
  }

  goToCreateReport() {
    this.$router.push({
      name: 'Suppliers/Purchases/Reports/Create',
    })
  }

  deleteReport(item) {
    this.deleteReports(item)
  }

  deleteReports(item) {
    const self = this

    if (confirm(this.$t('c:common:Are you sure you want to delete this item(s)?').toString())) {
      let selectedRows
      if (item === null) {
        selectedRows = self.selected
      } else {
        selectedRows = [item]
      }

      const deleteData = {
        selected_rows: selectedRows,
      }
      this.$axios
        .delete('/v3/reports/suppliers/purchases/list/delete_reports', { data: deleteData })
        .then((response) => {
          for (let i = 0; i < selectedRows.length; i++) {
            const index = this.reports.indexOf(selectedRows[i])
            this.reports.splice(index, 1)
          }
        })
    }
  }

  downloadReport(item) {
    const self = this

    const index = self.reports.indexOf(item)
    const reportData = self.reports[index]

    self.downloadReportLoading = true

    this.$axios
      .post('/v3/reports/suppliers/purchases/download_report', { report_id: reportData.id })
      .then(function (response) {
        const blob = b64toBlob(response.data.data.binary, 'application/vnd.ms-excel')
        if (!window.navigator.msSaveOrOpenBlob) {
          // BLOB NAVIGATOR
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', self.getExcelFileName(reportData))
          document.body.appendChild(link)
          link.click()
        } else {
          // BLOB FOR EXPLORER 11
          const url = window.navigator.msSaveOrOpenBlob(blob, self.getExcelFileName(reportData))
        }
      })
      .finally(function () {
        self.downloadReportLoading = false
      })
  }

  openSendReportDialog(item) {
    const index = this.reports.indexOf(item)
    const reportData = this.reports[index]

    this.sendReportId = reportData.id

    this.sendReportEmails = null
    this.sendReportSubject = null
    this.sendReportMessage = null

    this.sendReportDialog = true
  }

  sendReport(item) {
    if (this.$refs.sendReportForm.validate()) {
      const self = this

      self.sendReportLoading = true

      this.$axios
        .post('/v3/reports/suppliers/purchases/send_report', this.sendReportRequestData)
        .then(function(response) {})
        .finally(function () {
          self.sendReportLoading = false
          self.sendReportDialog = false
        })
    }
  }
}
